<template>
	<div>
		<CCard class="p-4">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow>
				
						<CCol lg="6">
							<h5 v-if="!editMode">Add Parts Entry</h5>
							<h5 v-else>Update Parts Entry</h5>
						</CCol>
				     	<CCol lg="6">
				      		<div style="text-align: right">
				      				<CButton size="sm"  class="btn btn-ghost-dark mr-1"  @click="backToTable()"><i class="fa fa-close"></i> Cancel</CButton> &nbsp; 
					      			<CButton size="sm" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
				      		</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CCard class="p-4 pl-5 pr-5 ml-5 mr-5 mt-2">
						<CRow class="mb-4">
							<CCol lg="6">
								<h6>Parts Entry</h6>
							</CCol> 
						
						</CRow>
						<CRow class="ml-3 mr-3">
							<CCol lg="6">
								<label class="mb-1">Part Identifier <span class="text-danger">*</span></label>
								<CInput
				                  v-model="dataParams.part_identifier"
				                  required="true"
				                />
							</CCol>
							<CCol lg="6">
								<div class="form-group"> 
									<label class="mb-1">Category <span class="text-danger">*</span></label>
									<v-select 
										label="setting_name" 
										:options="partsCategoryList.data"
										:reduce="item => item.id"
										v-model="dataParams.category_id"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.category_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							
						</CRow>
						<CRow class="ml-3 mr-3">
							
								<CCol lg="12">
								<label class="mb-1">Part Name <span class="text-danger">*</span></label>
								<CInput
				                  v-model="dataParams.part_name"
				                  required="true"
				                />
							
								</CCol>
							
						</CRow>
						<CRow class="ml-3 mr-3">
							
							<CCol lg="6">
								<label class="mb-1">Manufacturer </label>
								<CInput
				                  
				                  v-model="dataParams.manufacturer"
								 
				                />
							
								</CCol>
							<CCol lg="6">
								<div class="form-group"> 
									<label class="mb-1">Location</label>
									<v-select 
										label="setting_name" 
										:options="partsLocationList.data"
										:reduce="item => item.id"
										v-model="dataParams.part_location_id"
										
									> 
									</v-select>
								</div>
							</CCol>
							
								<CCol lg="6">
								<label class="mb-1">Model </label>
								<CInput
				                
				                  v-model="dataParams.model"
								 
				                />
							</CCol> 
							<CCol lg="3">
								<label class="mb-1">Stock <span class="text-danger">*</span></label>
								<CInput
				                  
				                  v-model="dataParams.stock_limit"
								  required="true"
								  type="number"
				                />
							</CCol> 
							<CCol lg="3">
								<div class="form-group"> 
									<label class="mb-1">Unit <span class="text-danger">*</span></label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												setting_name: 'pcs',
												id: 'pcs'
											},
											{
												setting_name: 'packs',
												id: 'pcks'
											}, 
											{
												setting_name: 'gal',
												id: 'gal'
											}, 
											{
												setting_name: 'ltrs',
												id: 'ltrs'
											},
										]"
										:reduce="item => item.id"
										v-model="dataParams.unit"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.unit"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
						</CRow>
						
						<CRow class="ml-3 mr-3">
							
								<CCol lg="12">
								<label class="mb-1">Description</label>
								<CInput
				                
				                  v-model="dataParams.description" 
				                />
							
								</CCol>
							
						</CRow>
					</CCard>
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script> 
import axios from '../axios';
import axioss from 'axios';
import config from '../config.js'; 
import Swal from 'sweetalert2';
// import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment';

export default {
	mounted(){  
		
		this.getPartsCategory();
		this.getPartLocationList();
		
		

		

		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	},
	data(){
		return{
			
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				part_identifier:"",
				category_id:"",
				part_name:"",
				manufacturer:"",
				part_location_id:"",
				model:"",
				stock_limit:"",
				unit:"",
				description:"",
				main_qty: 0,
				cost: 0,
				status: ""
			},
			editMode: false,
			selectedRow: {},
			vendorList: {
				data: []
			},
			partsCategoryList: {
				data: []
			},
			
			partsLocationList: {
				data: []
			},
		
			isLoading: false,
			
			config
		}
	},
	name: 'Tables',
	components: { Datepicker, vSelect},
	methods: { 
		 backToTable(){
	    	this.$router.push('/main/parts_inventory')
	    },


		getData(){

			axios.get(config.api_path+"/partInventory/"+this.$route.params.id)
			.then(response=>{
				this.dataParams = response.data.data 
			})

		}, 

		submit(){

			if(this.isLoading){return;}

	    	var ax = {}; 
	    	
	    	if(this.dataParams.part_location_id == ""){
	    		this.dataParams.part_location_id = null;
	    	}

	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/partInventory/"+this.$route.params.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/partInventory", this.dataParams);
	    	}
	    	ax.then(response => {
	    		var text = 'Part inventory successfully added!';
	    		if(this.editMode){
	    			text = 'Part inventory successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					part_identifier:"",
					category_id:"",
					part_name:"",
					manufacturer:"",
					part_location_id:"",
					model:"",
					stock_limit:"",
					unit:"",
					description:""
				} 
				this.$router.push('/main/view_parts_inventory/'+response.data.data.id)
	    	})
	    },


	    getPartsCategory(){

	    	axios.get(config.api_path+'/setting?setting_type=part_category&page=1&limit=10000000')
	    	.then(response => {
	    		this.partsCategoryList = response.data; 
	    	})

	    },

	    getMakerModel(){

	    	axios.get(config.api_path+'/setting?setting_type=maker_model&page=1&limit=10000000')
	    	.then(response => {
	    		this.makerModelList = response.data; 
	    	})

	    },

	    getPartLocationList(){

	    	axios.get(config.api_path+'/setting?setting_type=part_location&page=1&limit=10000000')
	    	.then(response => {
	    		this.partsLocationList = response.data; 
	    	})

	    },

	   

	  
 	}
}
</script>
